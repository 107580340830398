import PropTypes from 'prop-types'

import style from './horizontalCard.module.scss'

/**
 *
 * @param {Object} info
 * @param {Object} body
 * @param {Any} rest
 *
 * @returns {HTMLElement}
 */
function HorizontalCard({info, body, ...rest}) {
  return (
    <div key={info.id} className={style.items} {...rest}>
      <span className={style['icon-container']}>{info.icon}</span>
      <div className={style['info-container']}>{body}</div>
    </div>
  )
}

HorizontalCard.propTypes = {
  info: PropTypes.object.isRequired,
  body: PropTypes.object.isRequired,
}

export default HorizontalCard

import {useCallback, useContext, useRef} from 'react'
import {useCookies} from 'react-cookie'

import {AppContext} from 'appContext'
import {get, post, setIntoLocalStorage, useLogOut} from 'utils'
import {
  authAccessToken,
  authRefreshToken,
  userData,
  authUsername,
  authMaxAge,
  authExpires,
  userName,
  userBBStatus,
  userProfileStatus,
  userProfileId,
  signInMood,
} from 'shared'

/**
 * A custom hook to use auth get API
 */
function useAuthGet() {
  const {setAppState} = useContext(AppContext)

  const {logOut} = useLogOut()
  const [cookies, setCookie] = useCookies([authRefreshToken])

  const setCookieRef = useRef(setCookie)
  const setAppStateRef = useRef(setAppState)

  const authGet = useCallback(
    ({token = null, errorHandler = () => {}, abortController, mood = ''}) => {
      return get({
        endpoint: 'api/user/get/',
        token: token,
        abortController: abortController,
      }).then(
        response => {
          if (response) {
            setAppStateRef.current(prev => ({
              ...prev,
              name: response.name,
              userName: response.email,
              profileId: response.id,
              profileStatus: response.profile,
              bbStatus: response.bb,
              mobile: response.mobile,
              socialId: response.melli_code,
            }))
            setIntoLocalStorage(userData, {
              [authUsername]: response.email,
              [userName]: response.name,
              [userBBStatus]: response.bb,
              [userProfileStatus]: response.profile,
              [userProfileId]: response.id !== 0 ? response.id : null,
              [authExpires]: new Date(Date.now() + authMaxAge),
            })

            return Promise.resolve(response)
          }
        },
        error => {
          if (error.status === 401 && mood !== signInMood) {
            const data = {refresh: cookies[authRefreshToken]}

            post({endpoint: 'api/user/refresh-token/', body: data}).then(
              response => {
                if (response) {
                  setCookieRef.current(authAccessToken, response.token, {
                    maxAge: authMaxAge,
                    sameSite: 'lax',
                    path: '/',
                  })
                  setCookieRef.current(
                    authRefreshToken,
                    response.refresh_token,
                    {
                      maxAge: authMaxAge,
                      sameSite: 'lax',
                      path: '/',
                    },
                  )

                  setAppStateRef.current(prev => ({
                    ...prev,
                    accessToken: response.token,
                    refreshToken: response.refresh_token,
                  }))
                } else {
                  setAppStateRef.current(prev => ({
                    ...prev,
                    triggerSignIn: true,
                  }))
                }
              },
              error => {
                setAppStateRef.current(prev => ({
                  ...prev,
                  triggerSignIn: true,
                }))

                logOut()
              },
            )
          }

          errorHandler()
        },
      )
    },
    [cookies, logOut],
  )

  return {authGet}
}

export default useAuthGet

import {useContext, useMemo, useRef} from 'react'
import PropTypes from 'prop-types'
import {useMatch} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {AppContext} from 'appContext'
import {
  Profile,
  Money,
  ShoppingBasket,
  History,
  Wallet,
  Exit,
  Friends,
} from 'assets/icons'
import {ListItems, LoadingSpinner} from 'components'
import {noProfile} from 'shared'
import {useLogOut} from 'utils'
import {fullyVerified} from 'shared'

import style from './menuItems.module.scss'

/**
 *
 * @param {String} className
 * @returns
 */
function MenuItems({className = ''}) {
  const {appState} = useContext(AppContext)

  const {logOut} = useLogOut()
  const {t} = useTranslation()
  const matchInfo = useMatch({
    path: '/profile',
    exact: true,
  })

  const matchTransactionsHistory = useMatch('/profile/transactions-history')
  const matchPayAndTransfer = useMatch('/profile/pay-and-transfer')
  const matchWallet = useMatch('/profile/wallet')
  const matchIntelligentBasket = useMatch('/profile/intelligent-basket')
  const matchIntroduceFriends = useMatch('/profile/introduce-friends')

  const tRef = useRef(t)

  const logOutObject = useMemo(
    () => ({
      id: 'log-out',
      value: tRef.current('navigation.logOut'),
      icon: <Exit />,
      link: '/',
      match: '',
      onClick: () => logOut(),
    }),
    [logOut],
  )
  const navigationItems = useMemo(
    () => [
      {
        id: 'auth',
        value: tRef.current('navigation.auth'),
        icon: <Profile />,
        link: '/auth',
        match: '',
      },
      logOutObject,
    ],
    [logOutObject],
  )
  const verifiedUserNavigationItems = useMemo(
    () => [
      {
        id: 'info',
        value: tRef.current('navigation.info'),
        icon: <Profile />,
        link: '/profile',
        match: matchInfo,
      },
      {
        id: 'pay-and-transfer',
        value: tRef.current('navigation.payAndTransfer'),
        icon: <Money />,
        link: '/profile/pay-and-transfer',
        match: matchPayAndTransfer,
      },
      {
        id: 'intelligent-basket',
        value: tRef.current('navigation.intelligentBasketProfile'),
        icon: <ShoppingBasket />,
        link: '/profile/intelligent-basket',
        match: matchIntelligentBasket,
        style: style['intelligent-basket'],
      },
      {
        id: 'wallet',
        value: tRef.current('navigation.wallet'),
        icon: <Wallet />,
        link: '/profile/wallet',
        match: matchWallet,
      },
      {
        id: 'transactions-history',
        value: tRef.current('navigation.transactionsHistory'),
        icon: <History />,
        link: '/profile/transactions-history',
        match: matchTransactionsHistory,
      },
      {
        id: 'friends',
        value: tRef.current('navigation.introduceFriends'),
        icon: <Friends />,
        link: '/profile/introduce-friends',
        match: matchIntroduceFriends,
      },
      logOutObject,
    ],
    [
      matchInfo,
      matchIntelligentBasket,
      matchTransactionsHistory,
      matchPayAndTransfer,
      matchWallet,
      matchIntroduceFriends,
      logOutObject,
    ],
  )
  const listItems = useMemo(() => {
    return appState.profileStatus === fullyVerified
      ? verifiedUserNavigationItems
      : navigationItems
  }, [appState.profileStatus, navigationItems, verifiedUserNavigationItems])

  return appState.profileStatus || appState.profileStatus === noProfile ? (
    <ul className={`${style.list} ${className}`}>
      <ListItems items={listItems} />
    </ul>
  ) : (
    <LoadingSpinner parentDiv={true} />
  )
}

MenuItems.propTypes = {
  className: PropTypes.string,
}

export default MenuItems

import {useMemo, useRef} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {Tick} from 'assets/icons'
import {useWindowSize} from 'utils'
import {tabletScreen} from 'shared'

import style from './wizard.module.scss'

/**
 *
 * @param {number} step
 * @param {Array} titles
 * @param {Array} body
 *
 * @returns {HTMLElement}
 */
function Wizard({step = 0, titles = [], body}) {
  const {t} = useTranslation()
  const tRef = useRef(t)
  const {width} = useWindowSize()

  const titlesJSX = useMemo(
    () => (
      <ul className={style.list}>
        {titles.map((title, index) => {
          const titleClass =
            index !== step
              ? index > step
                ? `heading-04 ${style.disabled}`
                : `heading-04 ${style.valid}`
              : `heading-03 ${style.current}`
          const numberClass = titleClass.split(' ')[1]

          return (
            <li
              key={title.id}
              className={`${style.item} ${index === step ? style.active : ''}`}
            >
              <div className={`${numberClass} ${style.step}`}>
                <span className={style['step-number']}>
                  {numberClass === style.valid ? <Tick /> : index + 1}
                </span>
              </div>
              <h2 className={titleClass}>{title.value}</h2>
            </li>
          )
        })}
      </ul>
    ),
    [step, titles],
  )
  const titlesLength = useMemo(() => titles.length, [titles.length])
  const stepIndicator = useMemo(
    () => (
      <div className={style['step-indicator']}>
        <span className="heading-03">{step + 1}</span>
        <span className="heading-03"> {tRef.current('prepositions.from')}</span>
        <span className="heading-03">{titlesLength}</span>
      </div>
    ),
    [step, titlesLength],
  )
  const header = useMemo(
    () => (
      <div className={style.header}>
        {width > tabletScreen ? (
          <>
            {titlesJSX}
            <div className={style['border-line']} />
          </>
        ) : (
          <>
            {stepIndicator}
            <div className={`${style['step-name']} heading-04`}>
              {titles[step].value}
            </div>
          </>
        )}
      </div>
    ),
    [step, stepIndicator, titles, titlesJSX, width],
  )

  return (
    <div className={style.wizard}>
      {header}
      <div className={style.body}>{body[step]}</div>
    </div>
  )
}

Wizard.propTypes = {
  step: PropTypes.number,
  titles: PropTypes.arrayOf(PropTypes.object),
  body: PropTypes.array,
}

export default Wizard

const WALLET_URL = 'api/exchange/wallet'
const EDIT_WALLET_URL = 'api/exchange/wallet/edit'
const CREATE_WALLET_URL = 'api/exchange/wallet/create'
const ALL_CURRENCIES_URL = 'api/exchange/currencies/usdt'
const USDT_CURRENCIES_URL = 'api/exchange/currencies/usdt'
const USDT_SITE_WALLS = 'api/bestbuy/usdt-sitewallets/'
const SELL_CURRENCIES_URL = 'api/bestbuy/currencies/sell'
const BUY_CURRENCIES_URL = 'api/bestbuy/currencies/buy'
const BEST_BUY_ACTION_URL = 'api/bestbuy/action'
const CHARGE_LIST_URL = 'api/customer/charge/list'

export {
  WALLET_URL,
  EDIT_WALLET_URL,
  CREATE_WALLET_URL,
  ALL_CURRENCIES_URL,
  USDT_CURRENCIES_URL,
  USDT_SITE_WALLS,
  SELL_CURRENCIES_URL,
  BUY_CURRENCIES_URL,
  BEST_BUY_ACTION_URL,
  CHARGE_LIST_URL,
}

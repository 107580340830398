import {useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {Picture} from 'components'

import Step03PNG from './step03.png'
import Step03WebP from './step03.webp'

function Step03({...props}) {
  const {t} = useTranslation()

  const tRef = useRef(t)

  return (
    <Picture
      src={Step03PNG}
      alt={tRef.current('alts.step03')}
      srcSet={[Step03WebP, Step03PNG]}
      {...props}
    />
  )
}

export default Step03

import PropTypes from 'prop-types'

import {LoadingSpinner} from 'components'

import style from './largeCard.module.scss'

/**
 *
 * @param {Object} header
 * @param {Object} body
 * @param {String} status
 * @param {String} notFoundText
 * @param {Boolean} flexColumnBody
 * @param {Boolean} spacing
 * @param {Boolean} border
 * @param {Any} rest
 *
 * @returns {HTMLElement}
 */
function LargeCard({
  header,
  body,
  status,
  notFoundText,
  flexColumnBody = false,
  spacing = true,
  border = false,
  headerWithoutPadding = false,
  ...rest
}) {
  const showBody = Boolean(status === 'resolved' || !status)
  const showLoading = Boolean(status === 'pending' || status === 'idle')

  return (
    <div
      className={`${style.container} ${border ? style.border : ''}`}
      {...rest}
    >
      <div
        aria-label="inner-container"
        className={`${style['inner-container']} ${
          spacing ? style.spacing : ''
        }`}
      >
        <div
          className={`${style.header} ${
            headerWithoutPadding ? style['no-padding'] : ''
          }`}
        >
          {header}
        </div>
        <div
          className={`${style.body} ${
            flexColumnBody ? style['flex-column-body'] : ''
          }`}
        >
          {showBody ? (
            body
          ) : showLoading ? (
            <LoadingSpinner />
          ) : notFoundText ? (
            <div
              aria-label="not-found"
              className={`${style['not-found']} desc-03`}
            >
              {notFoundText}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

LargeCard.propTypes = {
  header: PropTypes.object.isRequired,
  body: PropTypes.object.isRequired,
  status: PropTypes.string,
  notFoundText: PropTypes.string,
  flexColumnBody: PropTypes.bool,
  spacing: PropTypes.bool,
  border: PropTypes.bool,
  rest: PropTypes.any,
}

export default LargeCard

import {useEffect, useRef} from 'react'
import {gsap} from 'gsap'

import {ReactComponent as IntelligentBasketIcon} from './intelligentBasket.svg'

function IntelligentBasket({...rest}) {
  const intelligentBasketRef = useRef(null)

  useEffect(() => {
    const money = intelligentBasketRef.current.querySelector('#money')
    const suckerBack =
      intelligentBasketRef.current.querySelector('#sucker-back')
    const sucker = intelligentBasketRef.current.querySelector('#sucker')
    const cog = intelligentBasketRef.current.querySelector('#cog')
    const increasingArrow =
      intelligentBasketRef.current.querySelector('#increasing-arrow')
    const coinBasket =
      intelligentBasketRef.current.querySelector('#coin-basket')
    const bar1 = intelligentBasketRef.current.querySelectorAll('#bar-1 > rect')
    const bar2 = intelligentBasketRef.current.querySelectorAll('#bar-2 > rect')
    const bar3 = intelligentBasketRef.current.querySelectorAll('#bar-3 > rect')
    const bar4 = intelligentBasketRef.current.querySelector('#bar-4')

    const timeLine = gsap.timeline({repeat: -1})

    timeLine
      .set(money, {x: '-300', y: '-300'})
      .set(suckerBack, {
        scale: 1.2,
        x: '95',
        y: '215',
        transformOrigin: '50% 50%',
      })
      .set(sucker, {scale: 1.1, transformOrigin: '50% 50%'})
      .set(coinBasket, {x: '400'})
      .set(increasingArrow, {scale: 0.1})
      .set(increasingArrow, {x: '-450', y: '-80', scale: 0.1})
      .set([bar1, bar2, bar3, bar4], {rotate: '180deg'})
      .set(bar1, {x: '14', y: '20'})
      .set(bar2, {x: '11', y: '37'})
      .set(bar3, {x: '11', y: '30'})
      .set(bar4, {x: '97', y: '97'})

    timeLine
      .to(money, 1, {x: '165', y: '270', scale: 0.6}, 'money')
      .to(suckerBack, 1, {scale: 1}, 'money+=0.3')
      .to(sucker, 1, {scale: 0.9}, 'money+=0.3')
      .to(suckerBack, 1, {scale: 1.2}, 'money+=0.7')
      .to(sucker, 1, {scale: 1.1}, 'money+=0.7')
      .to(
        cog,
        3,
        {
          rotation: '+=360',
          transformOrigin: '50% 50%',
        },
        'start',
      )
      .to(
        increasingArrow,
        1,
        {
          scale: 1,
          transformOrigin: '50% 50%',
        },
        'start',
      )
      .to(
        bar1,
        1,
        {
          height: '50',
        },
        'start+=0.3',
      )
      .to(
        bar2,
        1,
        {
          height: '80',
        },
        'start+=0.7',
      )
      .to(
        bar3,
        1,
        {
          height: '60',
        },
        'start+=1',
      )
      .to(
        bar4,
        1,
        {
          height: '29',
        },
        'start+=1.4',
      )
      .to(
        increasingArrow,
        1,
        {
          scale: 0.1,
        },
        'start+=1.5',
      )
      .to(
        bar1,
        1,
        {
          height: '18.746',
        },
        'start+=1.5',
      )
      .to(
        bar2,
        1,
        {
          height: '36.405',
        },
        'start+=1.8',
      )
      .to(
        bar3,
        1,
        {
          height: '28.635',
        },
        'start+=2.1',
      )
      .to(
        bar4,
        1,
        {
          height: '62.975',
        },
        'start+=2.5',
      )
      .to(coinBasket, 2, {x: '1050'}, 'start+=1')
  }, [])

  return <IntelligentBasketIcon ref={intelligentBasketRef} {...rest} />
}

export default IntelligentBasket

import {useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {Picture} from 'components'

import Step02PNG from './step02.png'
import Step02WebP from './step02.webp'

function Step02({...props}) {
  const {t} = useTranslation()

  const tRef = useRef(t)

  return (
    <Picture
      src={Step02PNG}
      alt={tRef.current('alts.step02')}
      srcSet={[Step02WebP, Step02PNG]}
      {...props}
    />
  )
}

export default Step02

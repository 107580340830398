import style from './loadingSpinner.module.scss'

/**
 *
 * @param {Boolean} parentDiv - if its true, the spinner will wrapped within the parent div
 * @param {String} display - the customized display class like d-none class
 * @param {String} stroke - color of stroke
 * @param {any} props - rest of props (like data attributes)
 *
 * @returns {HTMLDivElement} - it will return a loading spinner with the desired colored passed by stroke
 *
 */
function LoadingSpinner({
  parentDiv = false,
  withOutMinHeight = false,
  display = '',
  stroke = 'other',
  parentClass = '',
  ...props
}) {
  return (
    <div
      className={`${
        parentDiv
          ? `${style['custom-loader-container']}`
          : withOutMinHeight
          ? style['no-min-height']
          : style.default
      } ${display} ${style.container} ${parentClass}`}
      {...props}
    >
      <svg
        aria-label="spinner"
        className={style.spinner}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          aria-label="circle"
          className={`${style.path} ${
            style[`path-animation-stroke-${stroke}`]
          }`}
          fill="none"
          strokeWidth="5"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </div>
  )
}

export default LoadingSpinner

import {useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {Caret} from 'assets/icons'

import style from './tablePagination.module.scss'

const TablePagination = props => {
  const {t} = useTranslation()
  const tRef = useRef(t)

  const totalPages = props.pageOptions.length
  const lastPage = props.pageCount - 1
  const firstPage = 0

  return (
    <>
      {totalPages > 1 && (
        <div className={style['table-pagination']}>
          <div className={style['navigation-buttons-container']}>
            <button
              className={style['pagination-btn']}
              onClick={() => props.gotoPage(firstPage)}
              disabled={!props.canPreviousPage}
            >
              {tRef.current('pagination.first')}
            </button>
            <button
              className={`${style['pagination-btn']}${style.prev}`}
              onClick={props.previousPage}
              disabled={!props.canPreviousPage}
            >
              <Caret />
            </button>
            <ul className={style['table-pagination-list']}>
              {props.pageOptions.map((index, page) => {
                return (
                  <li
                    className={`${style['table-pagination-item']} ${
                      props.pageIndex === index ? style.active : ''
                    }`}
                    onClick={() => props.gotoPage(index)}
                    key={index}
                  >
                    {page + 1}
                  </li>
                )
              })}
            </ul>
            <button
              className={`${style['pagination-btn']}${style.next}`}
              onClick={props.nextPage}
              disabled={!props.canNextPage}
            >
              <Caret />
            </button>
            <button
              className={style['pagination-btn']}
              onClick={() => props.gotoPage(lastPage)}
              disabled={!props.canNextPage}
            >
              {tRef.current('pagination.last')}
            </button>
          </div>
          {totalPages > 1 && (
            <div className={style['table-pages-indexing']}>
              {`${tRef.current('pagination.page')} ${
                props.pageIndex + 1
              } ${tRef.current('pagination.from')}${totalPages}`}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default TablePagination

import * as React from 'react'
import {useTranslation} from 'react-i18next'

import {Button} from 'components'
import {setIntoLocalStorage, getFromLocalStorage} from 'utils'
import {lang, farsi} from 'shared'

import style from './language.module.scss'

function Language() {
  const initialStates = {
    lang: farsi,
    active: true,
  }

  const [state, setState] = React.useState(initialStates)

  const {t, i18n} = useTranslation()

  const langs = [
    {id: 'fa', name: t('lang.fa')},
    {id: 'en', name: t('lang.en')},
    {id: 'ar', name: t('lang.ar')},
  ]

  const listClickHandler = () =>
    setState(prevState => ({...prevState, active: !prevState.active}))

  const changeLanguageHandler = (event, value) => {
    setIntoLocalStorage(lang, value)

    setState(prevState => ({...prevState, lang: value}))

    const languageValue = value

    i18n.changeLanguage(languageValue)

    listClickHandler()
  }

  React.useEffect(() => {
    getFromLocalStorage(lang).then(res => {
      if (res) {
        setState(prevState => ({...prevState, lang: res}))

        i18n.changeLanguage(res)
      }
    })
  }, [i18n])

  return (
    <div className={style.container}>
      {!state.active && (
        <div className={style.overlay} onClick={() => listClickHandler()} />
      )}
      <Button
        className={`primary-outline ${!state.active ? style.active : ''}`}
        text={t(`lang.${state.lang}`)}
        onClick={() => listClickHandler()}
      />
      <ul className={style.list} aria-hidden={state.active}>
        {langs.map(lang => (
          <React.Fragment key={lang.id}>
            {lang.id !== state.lang && (
              <li
                data-value={lang.id}
                onClick={event => changeLanguageHandler(event, lang.id)}
              >
                {lang.name}
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  )
}

export default Language

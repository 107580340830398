import {useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {Caret} from 'assets/icons'
import {defaultPageSize} from 'shared'
import {usePagination, DOTS} from 'utils'

import style from './pagination.module.scss'
function Pagination({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize = defaultPageSize,
  className,
}) {
  const {t} = useTranslation()
  const tRef = useRef(t)

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    if (currentPage !== lastPage) {
      onPageChange(currentPage + 1)
    }
  }

  const onPrevious = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1)
    }
  }
  const onFirstPage = () => {
    if (currentPage !== 1) {
      onPageChange(1)
    }
  }
  const onLastPage = () => {
    if (currentPage !== lastPage) {
      onPageChange(lastPage)
    }
  }

  let lastPage = paginationRange[paginationRange.length - 1]
  return (
    <ul className={`${style['pagination-container']} ${className}`}>
      <li
        className={
          currentPage !== 1
            ? style['pagination-item']
            : `${style['pagination-item']} ${style['disabled']}`
        }
        onClick={onFirstPage}
      >
        {tRef.current('pagination.first')}
      </li>
      {/* Left navigation arrow */}
      <li
        className={
          currentPage !== 1
            ? style['pagination-item']
            : `${style['pagination-item']} ${style['disabled']}`
        }
        onClick={onPrevious}
      >
        <div className={`${style['arrow']} ${style['right']}`}>
          <Caret />
        </div>
      </li>
      {paginationRange.map(pageNumber => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <li
              key={pageNumber}
              className={`${style['pagination-item']} ${style['dots']}`}
            >
              &#8230;
            </li>
          )
        }

        // Render our Page Pills
        return (
          <li
            key={pageNumber}
            className={
              pageNumber === currentPage
                ? `${style['pagination-item']} ${style['selected']}`
                : `${style['pagination-item']}`
            }
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        )
      })}
      {/*  Right Navigation arrow */}
      <li
        className={
          currentPage === lastPage
            ? `${style['pagination-item']} ${style['disabled']}`
            : style['pagination-item']
        }
        onClick={onNext}
      >
        <div className={`${style['arrow']} ${style['left']}`}>
          <Caret />
        </div>
      </li>
      <li
        className={
          currentPage !== lastPage
            ? style['pagination-item']
            : `${style['pagination-item']} ${style['disabled']}`
        }
        onClick={onLastPage}
      >
        {tRef.current('pagination.last')}
      </li>
    </ul>
  )
}

export default Pagination

import {Banner} from 'components'

import style from './profileNotifications.module.scss'

function ProfileNotifications({...rest}) {
  return (
    <div className={style.container}>
      <Banner {...rest} />
    </div>
  )
}

export default ProfileNotifications

import {useEffect, useCallback, useContext, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {AppContext} from 'appContext'
import {
  useHttpRequests,
  getFromLocalStorage,
  setIntoLocalStorage,
  removeFromLocalStorage,
} from 'utils'
import {
  userData as userDataKey,
  userId,
  userName,
  userProfileStatus,
  userBBStatus,
  userProfileId,
  authUsername,
  stats,
  statsMaxAge,
  farsi,
  arabic,
} from 'shared'

function LiveAppState() {
  const {appState, setAppState} = useContext(AppContext)

  const {get} = useHttpRequests()
  const navigate = useNavigate()
  const {i18n} = useTranslation()

  const getRef = useRef(get)
  const isCancelled = useRef(false)
  const setAppStateRef = useRef(setAppState)

  const userDataHandler = useCallback(data => {
    setAppStateRef.current(prev => ({
      ...prev,
      userId: data?.[userId],
      name: data?.[userName],
      userName: data?.[authUsername],
      profileId: data?.[userProfileId],
      profileStatus: data?.[userProfileStatus],
      bbStatus: data?.[userBBStatus],
    }))
  }, [])

  const statNowRequestHandler = useCallback(abortController => {
    getRef
      .current({
        endpoint: 'api/system/stat/now',
        abortController: abortController,
      })
      .then(
        response => {
          setIntoLocalStorage(stats, {
            data: response,
            date: new Date(Date.now() + statsMaxAge),
          })

          setAppStateRef.current(prev => ({
            ...prev,
            statsNow: {
              data: response,
              date: new Date(Date.now() + statsMaxAge),
            },
          }))
        },
        error => console.error(error),
      )
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    const localStatsPromise = getFromLocalStorage(stats)

    localStatsPromise.then(localStats => {
      if (!localStats) {
        statNowRequestHandler(abortController)
      } else {
        if (Date.now() > new Date(localStats.date).getTime()) {
          removeFromLocalStorage(stats)

          setAppStateRef.current(prev => ({
            ...prev,
            statsNow: null,
          }))

          statNowRequestHandler(abortController)
        } else {
          if (!isCancelled.current) {
            setAppStateRef.current(prev => ({
              ...prev,
              statsNow: localStats,
            }))
          }

          setAppStateRef.current(prev => ({
            ...prev,
            statsNow: {
              data: localStats.data,
              date: localStats.date,
            },
          }))
        }
      }
    })

    return () => {
      isCancelled.current = true
      return abortController.abort()
    }
  }, [statNowRequestHandler])

  useEffect(() => {
    const userDataPromise = getFromLocalStorage(userDataKey)

    userDataPromise.then(userData => {
      if (userData) {
        userDataHandler(userData)
      }
    })
  }, [appState.accessToken, userDataHandler])

  useEffect(() => {
    const userDataPromise = getFromLocalStorage(userDataKey)

    userDataPromise.then(userData => {
      if (userData) {
        userDataHandler(userData)
      }
    })
  }, [appState.accessToken, userDataHandler])

  useEffect(() => {
    if (
      appState.triggerSignInOrSignUp ||
      appState.triggerSignInOrSignUpOrSignUpOnAction
    ) {
      navigate('/login')
    }
  }, [
    appState.triggerSignInOrSignUp,
    appState.triggerSignInOrSignUpOrSignUpOnAction,
    navigate,
  ])

  useEffect(() => {
    if (appState.accessToken) {
      setAppStateRef.current(prev => ({
        ...prev,
        triggerSignInOrSignUp: false,
        triggerSignInOrSignUpOrSignUpOnAction: false,
      }))
    }
  }, [
    appState.accessToken,
    appState.triggerSignInOrSignUp,
    appState.triggerSignInOrSignUpOrSignUpOnAction,
  ])

  useEffect(() => {
    const html = document.documentElement

    html.setAttribute('lang', i18n.language)
    html.setAttribute(
      'dir',
      i18n.language === farsi || i18n.language === arabic ? 'rtl' : 'ltr',
    )
  }, [i18n.language])

  return <></>
}

export default LiveAppState

import {Logo} from 'assets/icons'

import style from './sarrafehLoading.module.scss'

function SarrafehLoading() {
  return (
    <div className={style.container}>
      <Logo />
    </div>
  )
}

export default SarrafehLoading

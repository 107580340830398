import {lazy, useEffect, useState, useMemo, useContext, useRef} from 'react'

import {AppContext} from 'appContext'
import {mediumTabletScreen} from 'shared'
import {useWindowSize, useQuery} from 'utils'

import style from './header.module.scss'

const Welcome = lazy(() =>
  import('./modal/welcome' /* webpackChunkName: "welcome" */),
)
const PayWithRials = lazy(() =>
  import('./modal/payWithRials' /* webpackChunkName: "pay-with-rials" */),
)
const Menu = lazy(() => import('./menu' /* webpackChunkName: "menu" */))
const HamburgerMenu = lazy(() =>
  import('./hamburgerMenu' /* webpackChunkName: "hamburger-menu" */),
)

/**
 *
 * @returns {HTMLElement}
 *
 */
function Header() {
  const initialStates = useMemo(
    () => ({
      status: 'idle',
      resetPasswordModalStatus: false,
      name: null,
      copyStatus: false,
    }),
    [],
  )

  const {appState} = useContext(AppContext)

  const [, setState] = useState(initialStates)

  const query = useQuery()

  const windowSize = useWindowSize()

  const tokenRef = useRef(query.get('token'))

  useEffect(() => {
    if (tokenRef.current)
      setState(prev => ({...prev, resetPasswordModalStatus: true}))
  }, [])

  return (
    <header className={style['site-navbar']}>
      <div className={style.container}>
        {windowSize.width > mediumTabletScreen ? <Menu /> : <HamburgerMenu />}
      </div>
      {appState.welcomeModalStatus && <Welcome />}
      {appState.payWithRialsModalStatus && <PayWithRials />}
    </header>
  )
}

export default Header

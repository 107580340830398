import {
  thousandSeparator,
  arabicNumbers,
  persianNumbers,
  maximumInputLimit,
  minimumInputLimit,
  dot,
  slash,
} from 'shared'

const windowLocation = window.location

/**
 * @param  {Object} event
 */
function eyeHandler(event) {
  if (!event) return undefined
  const {target} = event
  const input = target.closest('div').querySelector('input')
  input.type === 'password' ? (input.type = 'text') : (input.type = 'password')
}

/**
 *
 * @param {Number|String} number
 *
 * @returns {String}
 */
function numberWithCommas(number) {
  if (!number) return number
  return number.toString().replace(thousandSeparator, ',')
}

/**
 *
 * @param {Number|String} number
 *
 * @returns {String}
 */
function convertDotToSlash(number) {
  if (!number) return number
  return number.toString().replace(dot, slash)
}

/**
 *
 * @param {String} string
 *
 * @returns {String}
 */
function convertNumbersToEnglish(string) {
  return string
    .replace(arabicNumbers, function (subString) {
      return subString.charCodeAt(0) - 1632
    })
    .replace(persianNumbers, function (subString) {
      return subString.charCodeAt(0) - 1776
    })
}

/**
 *
 * @param {Object} inputObj
 * @param {Integer} maxValue
 * @returns
 */
function maxAmountLimit(inputObj, maxValue) {
  const {value} = inputObj
  if (value <= maxValue) return inputObj
}

/**
 *
 * @param {Object} obj
 */
function dataLayerEvent(obj) {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    window.dataLayer.push(obj)
  }
}

/**
 *
 * @param {Array} currencies
 * @param {String} symbol
 *
 * @returns {Object}
 */
function currenciesData({currencies, symbol}) {
  return currencies.find(currency => currency.symbol === symbol)
}

/**
 *
 * @param {String} currency
 *
 * @returns {String}
 */
function currencyInFarsi(currency) {
  switch (currency?.toUpperCase()) {
    case 'BTC':
      return 'بیتـکویـن'
    case 'XRP':
      return 'ریپـل'
    case 'TRX':
      return 'تِـرون'
    case 'ETH':
      return 'اِتـریـوم'
    case 'USDT':
      return 'تِتِـر'
    default:
      break
  }
}

/**
 *
 * @param {String} currency
 * @param {String} value
 *
 * @returns {String}
 */
function decimalTruncate({currency, value}) {
  switch (currency) {
    case 'BTC':
      return parseFloat(value.toFixed(6))
    case 'ETH':
      return parseFloat(value.toFixed(4))
    case 'XRP':
    case 'TRX':
    case 'USDT':
      return parseFloat(value.toFixed(1))
    default:
      return value.toString()
  }
}

/**
 *
 * @param {String} network
 *
 * @returns {String}
 */
function networkInFarsi(network) {
  switch (network) {
    case 'BM':
      return 'بیتـکویـن (Main Net)'
    case 'RN':
      return 'ریپـل'
    case 'TRC20':
      return 'تِـرون (TRC20)'
    case 'ERC20':
      return 'اِتـریـوم (ERC20)'
    default:
      return network
  }
}

const currencyNetwork = {
  BTC: [
    {
      value: 'BM',
      label: 'بيتكوين (Main Net)',
    },
  ],
  XRP: [
    {
      value: 'RN',
      label: 'ریپـل',
    },
  ],
  TRX: [
    {
      value: 'TRC20',
      label: 'تِـرون (TRC20)',
    },
  ],
  ETH: [
    {
      value: 'ERC20',
      label: 'اِتـریـوم (ERC20)',
    },
  ],
  USDT: [
    {
      value: 'TRC20',
      label: 'تِـرون (TRC20)',
    },
    {
      value: 'ERC20',
      label: 'اِتـریـوم (ERC20)',
    },
  ],
}

/**
 *
 * @param {String} error
 *
 * @returns {String}
 */
function specialErrorsInFarsi(error) {
  switch (error) {
    case 'Authentication credentials were not provided.':
      return 'بـرای انـجام این مرحـله نیـاز اسـت تا با نام کاربـری و رمز عبور وارد شویـد'
    case 'No active account found with the given credentials':
      return 'هـیچ کاربـر فعـالی با آدرس ایمیل و گذرواژه وارد شده یافـت نشـد'
    default:
      return error
  }
}

/**
 *
 * @param {String} value
 *
 */
function copyToClipboard(value) {
  navigator.clipboard.writeText(value)
}

/**
 *
 *  @param {String} name
 *  @param {String} value
 */
function copyPromotionsLink({name, value}) {
  copyToClipboard(
    `${windowLocation.hostname}${
      windowLocation.port && windowLocation.port !== '80'
        ? `:${windowLocation.port}`
        : ''
    }/?${name}=${value}`,
  )
}

/**
 *
 * @param {Number} value
 *
 * @returns {Number}
 */
function setInputLimit({value, limitType}) {
  return !isNaN(value)
    ? value
    : limitType === 'min'
    ? minimumInputLimit
    : maximumInputLimit
}

function iOSUserAgent() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

function checkWebPSupport() {
  var elem = document.createElement('canvas')

  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
  } else {
    return false
  }
}

export {
  eyeHandler,
  numberWithCommas,
  convertDotToSlash,
  convertNumbersToEnglish,
  maxAmountLimit,
  dataLayerEvent,
  currenciesData,
  currencyInFarsi,
  decimalTruncate,
  networkInFarsi,
  currencyNetwork,
  specialErrorsInFarsi,
  copyToClipboard,
  copyPromotionsLink,
  setInputLimit,
  iOSUserAgent,
  checkWebPSupport,
}

import {useState, useMemo, createContext} from 'react'

const AppContext = createContext()

function AppProvider(props) {
  const initialStates = useMemo(
    () => ({
      accessToken: null,
      bbStatus: null,
      caretStatus: false,
      mobile: null,
      name: null,
      payWithRialsModalStatus: false,
      profileId: null,
      profileStatus: null,
      referralCode: null,
      refreshCredit: false,
      refreshToken: null,
      statsNow: null,
      triggerSignIn: false,
      userId: null,
      userInfo: null,
      userName: null,
      welcomeModalStatus: false,
      cash: 0,
    }),
    [],
  )

  const [appState, setAppState] = useState(initialStates)
  const value = {appState, setAppState, initialStates}

  return <AppContext.Provider value={value} {...props} />
}

export {AppProvider, AppContext}

export default AppContext

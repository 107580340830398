import {useCallback, cloneElement, useContext, useRef} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

import {AppContext} from 'appContext'

import style from './listItems.module.scss'

function ListItems({items, children = <></>}) {
  const {setAppState} = useContext(AppContext)

  const setAppStateRef = useRef(setAppState)

  const listOnClickHandler = useCallback(event => {
    const {target} = event

    if (target.tagName !== 'A' || target.tagName !== 'SPAN')
      target.closest('li').querySelector('a').click()
  }, [])

  const childrenWithOnClickHandler = cloneElement(children, {
    onClick: listOnClickHandler,
  })

  return (
    <>
      {items.map(item => (
        <li
          key={item.id}
          data-id={item.id}
          className={`${style.item} ${item.match ? style.active : ''}`}
          onClick={event => {
            setAppStateRef.current(prev => ({...prev, caretStatus: false}))
            if (item.onClick) {
              item.onClick()
            } else {
              listOnClickHandler(event)
            }
          }}
        >
          <Link to={item.link} className={style.link}>
            <span className={style.icon}>{item.icon}</span>
            <span
              className={`${style.text} desc-03 ${
                item.style ? item.style : ''
              }`}
            >
              {item.value}
            </span>
          </Link>
        </li>
      ))}
      {childrenWithOnClickHandler}
    </>
  )
}

ListItems.propTypes = {
  items: PropTypes.array,
  children: PropTypes.object,
}

export default ListItems

import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {Button} from 'components'
import {NotFound as NotFoundIcon} from 'assets/icons'

import style from './notFound.module.scss'

/**
 *
 * @param {bool} backButton
 */
function NotFound({backButton = true}) {
  const {t} = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={style.container}>
      <div className={style.title}>
        <NotFoundIcon />
      </div>
      <div className={`${style.description} heading-02`}>
        {t('errors.notFound')}
      </div>
      {backButton && (
        <Button
          onClick={() => navigate('/')}
          className={`${style.back} primary large`}
          text={t('buttons.home')}
        />
      )}
    </div>
  )
}

NotFound.propTypes = {
  backButton: PropTypes.bool.isRequired,
}

export default NotFound

import {useState, useMemo, useRef, useCallback} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {CaretDown} from 'assets/icons'
import {Button} from 'components'

import style from './accordion.module.scss'

/**
 *
 * @param {String} title
 * @param {String} content
 *
 * @returns {HTMLElement}
 */
function Accordion({title, content, ...rest}) {
  const initialStates = useMemo(
    () => ({
      active: true,
      height: '100%',
    }),
    [],
  )

  const [state, setState] = useState(initialStates)

  const {t} = useTranslation()

  const tRef = useRef(t)
  const contentRef = useRef(null)

  const {active, height} = state

  const toggleAccordion = useCallback(() => {
    setState(prev => ({
      ...prev,
      active: !prev.active,
      height: prev.active ? '0px' : `${contentRef.current.scrollHeight}px`,
    }))
  }, [])

  return (
    <div className={style['section']}>
      <div
        className={`${style['header']} ${active ? style.active : ''}`}
        onClick={() => toggleAccordion()}
      >
        <h2 className="heading-03">{title}</h2>
        <Button
          className="primary"
          containerClassName={style.button}
          text={
            active
              ? tRef.current('buttons.close')
              : tRef.current('buttons.view')
          }
          icon={
            <CaretDown
              className={`${style.caret} ${active ? style.rotate : ''}`}
            />
          }
        />
      </div>
      <div
        ref={contentRef}
        style={{maxHeight: `${height}`}}
        className={style['content']}
      >
        {content}
      </div>
    </div>
  )
}

Accordion.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
}

export default Accordion

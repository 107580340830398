// This is a place to store global numbers

// Cookie and localStorage max age
const authMaxAge = 60 * 60 * 24 * 14 * 1000
const statsMaxAge = 60 * 2 * 1000
const stateWeeklyMaxAge = 60 * 30 * 1000

// Profile status flags
const noProfile = 0
const fullyVerified = 1

// Optimal purchase status flags
const notVerifiedYet = 0
const requestIntelligentBasketForFirstTime = 1
const createBasket = 2
const basketInQueue = 3
const increaseCapital = 4

// Default input limits
const minimumInputLimit = 100
const maximumInputLimit = 5000

// Input files size limits
const minimumFileSize = 10000
const maximumFileSize = 30000000
const ByteToMBRatio = 1000000

// Screen Media
const mobileScreen = 480
const tabletScreen = 767
const mediumTabletScreen = 980
const mediumDesktopScreen = 1100
const largeScreen = 1200
const largeIshScreen = 1300

// Scroll from top
const scrollWhenPromotionIsOn = 70
const scrollWhenPromotionIsOnAndAuthenticationBannerIsOn = 150

// Default pagination values
const defaultPageSize = 2

// Default OTP timeouts
const otpTimeoutMinutes = 1
const otpTimeoutSeconds = 0

// Default server status codes
const unAuthorizedCode = 401
const serverErrorCode = 500

export {
  mobileScreen,
  largeIshScreen,
  authMaxAge,
  statsMaxAge,
  stateWeeklyMaxAge,
  noProfile,
  fullyVerified,
  notVerifiedYet,
  requestIntelligentBasketForFirstTime,
  createBasket,
  basketInQueue,
  increaseCapital,
  minimumInputLimit,
  maximumInputLimit,
  minimumFileSize,
  maximumFileSize,
  ByteToMBRatio,
  tabletScreen,
  mediumTabletScreen,
  mediumDesktopScreen,
  largeScreen,
  scrollWhenPromotionIsOn,
  scrollWhenPromotionIsOnAndAuthenticationBannerIsOn,
  defaultPageSize,
  otpTimeoutMinutes,
  otpTimeoutSeconds,
  unAuthorizedCode,
  serverErrorCode,
}

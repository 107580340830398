import {useMemo, useCallback, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {Button} from 'components'

import style from './authenticationNotice.module.scss'

function AuthenticationNotice({setState, stateName = 'modalStatus'}) {
  const {t} = useTranslation()
  const tRef = useRef(t)
  const navigate = useNavigate()

  const documentBody = useMemo(() => document.body, [])

  const modalCloseHandler = useCallback(() => {
    setState(prev => ({...prev, [stateName]: false}))

    documentBody.style.overflow = ''
  }, [documentBody.style, setState, stateName])

  return (
    <>
      <p className="desc-02">{tRef.current('homeContents.authNotice')}</p>
      <div className={style['buttons-container']}>
        <Button
          className="primary-outline large"
          text={tRef.current('buttons.cancel')}
          onClick={() => modalCloseHandler()}
        />
        <Button
          className="primary large"
          text={tRef.current('buttons.auth')}
          onClick={() => {
            documentBody.style.overflow = ''

            navigate('/auth')
          }}
        />
      </div>
    </>
  )
}

export default AuthenticationNotice

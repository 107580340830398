import {useState, useEffect} from 'react'

function usePageYOffset() {
  const [scrollY, setScrollY] = useState(0)

  function logScroll() {
    setScrollY(window.pageYOffset)
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', logScroll)
    }

    watchScroll()

    return () => {
      window.removeEventListener('scroll', logScroll)
    }
  }, [])

  return {scrollY: scrollY}
}

export default usePageYOffset

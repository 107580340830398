import {
  useState,
  useEffect,
  useMemo,
  useContext,
  useRef,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {AppContext} from 'appContext'
import {LoadingSpinner} from 'components'
import {
  convertDotToSlash,
  numberWithCommas,
  useHttpRequests,
  useWindowSize,
} from 'utils'
import {fullyVerified, mediumTabletScreen} from 'shared'

import style from './credit.module.scss'

/**
 *
 * @param {String} className
 * @returns
 */
function Credit({className = ''}) {
  const initialStates = useMemo(
    () => ({
      status: 'idle',
      totalBalance: 0,
    }),
    [],
  )

  const {appState, setAppState} = useContext(AppContext)

  const [state, setState] = useState(initialStates)

  const {t} = useTranslation()
  const {get} = useHttpRequests()
  const {width} = useWindowSize()

  const tRef = useRef(t)
  const getRef = useRef(get)
  const setAppStateRef = useRef(setAppState)

  const {status, totalBalance} = state

  const customerCredit = useCallback(
    abortController => {
      setState(prev => ({...prev, status: 'pending'}))

      getRef
        .current({
          endpoint: 'api/customer/usdt-cash',
          abortController: abortController,
          token: appState.accessToken,
        })
        .then(
          response => {
            if (response) {
              setState(prev => ({
                ...prev,
                totalBalance: numberWithCommas(
                  convertDotToSlash(
                    parseFloat(parseFloat(response.cash)?.toFixed(2)),
                  ),
                ),
                status: 'resolved',
              }))
              setAppStateRef.current(prev => ({...prev, cash: response.cash}))
            } else {
              setState(prev => ({
                ...prev,
                status: 'rejected',
              }))
            }
          },
          error =>
            setState(prev => ({
              ...prev,
              status: 'rejected',
            })),
        )
        .then(() =>
          setAppStateRef.current(prev => ({...prev, refreshCredit: false})),
        )
    },
    [appState.accessToken],
  )

  useEffect(() => {
    const abortController = new AbortController()

    if (appState.accessToken && appState.profileStatus === fullyVerified) {
      customerCredit(abortController)
    }

    return () => abortController.abort()
  }, [appState.accessToken, appState.profileStatus, customerCredit])
  useEffect(() => {
    const abortController = new AbortController()

    if (appState.refreshCredit) {
      customerCredit(abortController)
    }

    return () => abortController.abort()
  }, [appState.refreshCredit, customerCredit])

  return (
    <div className={`${style['profile-info']} ${className}`}>
      <span className="desc-02">
        {appState.name || appState.userName || tRef.current('labels.user')}
      </span>
      {appState.profileStatus === fullyVerified ? (
        <>
          {status === 'idle' || status !== 'pending' ? (
            <div className={style['total-balance']}>
              <span className="desc-02">
                {tRef.current('labels.totalBalance')}
              </span>
              <span className={`${style['balance-value']} desc-02`}>
                {totalBalance}
                <span className="desc-04">{tRef.current('labels.tether')}</span>
              </span>
            </div>
          ) : (
            <LoadingSpinner
              withOutMinHeight={true}
              parentClass={style.loading}
              stroke={width > mediumTabletScreen ? 'white' : 'dark'}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

Credit.propTypes = {
  className: PropTypes.string,
}

export default Credit

import {useContext, useRef} from 'react'
import {useCookies} from 'react-cookie'
import {useNavigate} from 'react-router-dom'

import {AppContext} from 'appContext'
import {
  authAccessToken,
  userData,
  authUsername,
  userReferralCode,
  authRefreshToken,
} from 'shared'
import {removeFromLocalStorage} from 'utils'

function useLogOut() {
  const {setAppState, initialStates} = useContext(AppContext)

  const navigate = useNavigate()
  const [, , removeCookie] = useCookies()

  const setAppStateRef = useRef(setAppState)
  const removeCookiesRef = useRef(removeCookie)
  const documentHTML = useRef(document.documentElement)

  const logOut = async (states = {}) => {
    await removeCookiesRef.current(authAccessToken, {path: '/'})
    await removeCookiesRef.current(authUsername, {path: '/'})
    await removeCookiesRef.current(userReferralCode, {path: '/'})
    await removeCookiesRef.current(authRefreshToken, {path: '/'})
    await removeFromLocalStorage(userData)

    setAppStateRef.current(prev => ({...prev, ...initialStates}))

    documentHTML.current.style.overflow = ''

    navigate('/')
  }

  return {logOut}
}

export default useLogOut

import {useState, useEffect, useCallback} from 'react'

/**
 * Custom hook for listening to window size change
 */
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  const handleResize = useCallback(
    () =>
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    [],
  )

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  return windowSize
}

export default useWindowSize

import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import farsi from './fa.json'
import english from './en.json'
import arabic from './ar.json'

const resources = {
  fa: {
    translation: farsi,
  },
  en: {
    translation: english,
  },
  ar: {
    translation: arabic,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'fa',
  fallbackLng: 'fa',

  keySeparator: '.',

  interpolation: {
    escapeValue: false,
  },
})

export default i18n

const primary = '#cc951c'
const secondary = '#d4a849'
const tertiary = '#b5a240'

const primaryDark = '#000000'
const secondaryDark = '#908e8e'
const senaryDark = '#333'

const primaryLight = '#efefef'

const primaryBorder = '#212b3e'
const secondaryBorder = '#272f3f'
const tertiaryBorder = '#f27e0b'
const quaternaryBorder = '#44526e'
const quinaryBorder = '#f3b221'
const senaryBorder = '#0e182b'

const primaryDisabled = '#4a5568'

const primaryActive = '#060e1c'

const primaryValid = '#37a450'

const primaryError = '#dd2c41'

const primaryPlaceholder = '#c2c2c2'

const primaryShadow = 'rgba(255, 255, 255, 0.1)'

const primaryHeader = '#1c2430'

const primaryTheme = '#000b21'
const secondaryTheme = '#091427'
const tertiaryTheme = '#111b2d'
const quaternaryTheme = '#071225'

const primaryText = '#d5d5d5'
const secondaryText = '#707070'
const tertiaryText = '#9c9d9e'

const ripple = '#1276a8'
const tron = '#dd2c41'
const bitcoin = '#f7931a'
const tether = '#00a478'
const ethereum = '#e3e3e3'

export {
  primary,
  secondary,
  tertiary,
  primaryDark,
  secondaryDark,
  senaryDark,
  primaryLight,
  primaryBorder,
  secondaryBorder,
  tertiaryBorder,
  quaternaryBorder,
  quinaryBorder,
  senaryBorder,
  primaryDisabled,
  primaryActive,
  primaryValid,
  primaryError,
  primaryPlaceholder,
  primaryShadow,
  primaryHeader,
  primaryTheme,
  secondaryTheme,
  tertiaryTheme,
  quaternaryTheme,
  primaryText,
  secondaryText,
  tertiaryText,
  ripple,
  tron,
  bitcoin,
  tether,
  ethereum,
}

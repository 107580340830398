// Storage items name
const authAccessToken = '__auth_access_token__'
const authRefreshToken = '__auth_refresh_token__'
const userData = '__user_data__'
const userId = '__user_id__'
const userName = '__user_name__'
const userProfileStatus = '__user_profile_status__'
const userPromotion = '__user_promotion__'
const rejectionComment = '__rejection_comment__'
const userBBStatus = '__user_bb_status__'
const userProfileId = '__user_profile_id__'
const userReferralCode = '__user_referral_code__'
const authUsername = '__auth_username__'
const authExpires = '__auth_expires__'
const stats = '__stats__'
const statsWeekly = '__stats_weekly__'
const lang = '__lang__'

// data layer event name
const bestBuyCreateBasketEvent = 'best-buy-buy'
const bestBuySellBasketEvent = 'best-buy-sell'
const bestBuyIncreaseBasketEvent = 'best-buy-add'
const bestBuyRebalanceBasketEvent = 'best-buy-rebalance'
const signUpEvent = 'sign-up'
const registerStep01Event = 'register-step01'
const registerStep02Event = 'register-step02'
const registerStep03Event = 'register-step03'
const registerStep04Event = 'register-step04'
const buyEvent = 'buy'
const sellEvent = 'sell'

// Social Media Links
const telegramAddress = 'https://t.me/sarrafeh'
const instagramAddress = 'https://www.instagram.com/sarrafehex/'
const whatsAppAddress = 'https://api.whatsapp.com/send?phone=989027860484'
const blogAddress = 'https://sarrafeh.com/blog'
const analyzerAddress = 'https://analyzer.sarrafeh.com'

// Social Media Links for share text
const telegramShareLink = `https://t.me/share/url?url=${window.location.hostname}&text=`
const whatsAppShareLink = 'https://api.whatsapp.com/send?text='
const twitterShareLink = 'https://twitter.com/intent/tweet?text='
const smsShareLink = 'sms://?&body='

// Video Links
const aboutVideoWebM = 'https://api.sarrafeh.io/media/video/sarrafeh.webm'
const aboutVideoMP4 = 'https://api.sarrafeh.io/media/video/sarrafeh.mp4'
const bestBuyVideoWebM = 'https://api.sarrafeh.io/media/video/bestbuy.webm'
const bestBuyVideoMP4 = 'https://api.sarrafeh.io/media/video/bestbuy.mp4'
const walletCreationVideoWebM =
  'https://api.sarrafeh.io/media/video/trustwallet-tutorial-sarrafeh.webm'
const walletCreationVideoMP4 =
  'https://api.sarrafeh.io/media/video/trustwallet-tutorial-sarrafeh.mp4'

// Buying Rials
const usdtNetwork = 'TRC20'
const usdtWalletAddress = 'TNVnEkukjgxhnqwr1ruViVgNbjFgMJQVvY'
const payWithRialsAddress = 'https://abantether.com/register?code=VFK6'

// Tracking Codes
const googleAnalyticsTrackingId = 'UA-195714354-1'
const googleTagManagerTrackingId = 'GTM-PFPFKZS'

// Moods
const signInMood = 'signIn'

// Languages
const farsi = 'fa'
const english = 'en'
const arabic = 'ar'

// Rates
const rebalanceWage = '0.75%'
const exitWage = '1%'

// ENVs
const PRODUCTION = 'production'

export {
  authAccessToken,
  authRefreshToken,
  userData,
  userId,
  authUsername,
  authExpires,
  aboutVideoWebM,
  aboutVideoMP4,
  bestBuyVideoWebM,
  bestBuyVideoMP4,
  walletCreationVideoWebM,
  walletCreationVideoMP4,
  userName,
  userProfileStatus,
  rejectionComment,
  userBBStatus,
  userReferralCode,
  stats,
  statsWeekly,
  lang,
  userPromotion,
  bestBuyCreateBasketEvent,
  bestBuySellBasketEvent,
  bestBuyIncreaseBasketEvent,
  bestBuyRebalanceBasketEvent,
  signUpEvent,
  registerStep01Event,
  registerStep02Event,
  registerStep03Event,
  registerStep04Event,
  buyEvent,
  sellEvent,
  userProfileId,
  telegramAddress,
  instagramAddress,
  whatsAppAddress,
  blogAddress,
  analyzerAddress,
  payWithRialsAddress,
  usdtWalletAddress,
  usdtNetwork,
  whatsAppShareLink,
  telegramShareLink,
  twitterShareLink,
  smsShareLink,
  googleAnalyticsTrackingId,
  googleTagManagerTrackingId,
  signInMood,
  farsi,
  english,
  arabic,
  rebalanceWage,
  exitWage,
  PRODUCTION,
}

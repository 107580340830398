import PropTypes from 'prop-types'

import style from './simpleCard.module.scss'

/**
 * @param {Object} data
 *
 * @returns {HTMLElement}
 *
 */
function SimpleCard({data}) {
  return (
    <div className={style.container}>
      {data.map(datum => (
        <div key={datum.id} className={style.row}>
          <span className={`${style.title} desc-03`}>{datum.title}</span>
          <span className={`${style.value} desc-04`}>{datum.value}</span>
        </div>
      ))}
    </div>
  )
}

SimpleCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}

export default SimpleCard

import {
  useState,
  useMemo,
  useRef,
  useCallback,
  Fragment,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {Copy, QrCode} from 'assets/icons'
import {Snackbar} from 'components'
import {
  currencyInFarsi,
  convertDotToSlash,
  networkInFarsi,
  copyToClipboard,
} from 'utils'
import {locale} from 'shared'

import style from './txIdConfirmation.module.scss'

/**
 * @params {Object} data
 *
 * @returns {HTMLElement}
 */
function TxIdConfirmation({data, children}) {
  const initialStates = useMemo(
    () => ({
      copyStatus: false,
    }),
    [],
  )

  const [state, setState] = useState(initialStates)

  const {t} = useTranslation()

  const tRef = useRef(t)

  const {copyStatus} = state

  const detailsData = useMemo(
    () => [
      {
        id: 'currency',
        label: tRef.current('labels.currency'),
        value: currencyInFarsi(data.currency),
      },
      {
        id: 'count',
        label: tRef.current('labels.count'),
        value: convertDotToSlash(data?.amount),
      },
      {
        id: 'network',
        label: tRef.current('labels.network'),
        value: networkInFarsi(data.network?.[locale] || data.network),
      },
      {
        id: 'distTag',
        ...(data.dist_tag &&
          data.dist_tag !== 'None' && {
            label: tRef.current('labels.distTag'),
            value: data?.dist_tag,
          }),
      },
    ],
    [data?.amount, data.currency, data.dist_tag, data.network],
  )

  const walletCopyHandler = useCallback(() => {
    copyToClipboard(data.address)

    setState(prev => ({
      ...prev,
      copyStatus: true,
    }))
  }, [data.address, setState])

  useEffect(() => {
    const timeout = setTimeout(
      () => setState(prev => ({...prev, copyStatus: false})),
      3000,
    )

    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className={style['details-container']}>
      {detailsData.map(data => (
        <Fragment key={data.id}>
          {data.label && (
            <div className={style.detail}>
              <span className={style['detail-label']}>{data.label}</span>
              <span className={style['detail-value']}>{data.value}</span>
            </div>
          )}
        </Fragment>
      ))}
      <div className={`${style.detail} ${style['address-area']}`}>
        <span className={style['detail-label']}>
          {tRef.current('labels.walletAddress')}
        </span>
        <div className={`${style['detail-value']} ${style['address']}`}>
          <ul className={style['address-icon']}>
            {data.qr_code && (
              <li className={style['address-icon-element']}>
                <QrCode />
                <div className={style['qr-code']}>
                  {data.qr_code && (
                    <img
                      className={style['qr-code-img']}
                      src={data.qr_code}
                      alt="QRcode"
                    />
                  )}
                </div>
              </li>
            )}
            <li
              className={`${style['address-icon-element']} ${style['copy-area']}`}
              onClick={() => walletCopyHandler()}
            >
              <Copy />
              <span className={`${style['address-text']} eng-font`}>
                {data.address}
              </span>
            </li>
          </ul>
        </div>
        <div className={style['qr-code-area']}>
          <img
            className={style['qr-code-img']}
            src={data.qr_code}
            alt="QRcode"
          />
        </div>
      </div>
      {children}
      {copyStatus && (
        <Snackbar
          setState={setState}
          text={tRef.current('notices.copySuccess')}
          type={'success'}
          stateName="copyStatus"
        />
      )}
    </div>
  )
}

TxIdConfirmation.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TxIdConfirmation

import {useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {Picture} from 'components'

import Step01PNG from './step01.png'
import Step01WebP from './step01.webp'

function Step01({...props}) {
  const {t} = useTranslation()

  const tRef = useRef(t)

  return (
    <Picture
      src={Step01PNG}
      alt={tRef.current('alts.step01')}
      srcSet={[Step01WebP, Step01PNG]}
      {...props}
    />
  )
}

export default Step01

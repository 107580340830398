import {useState, useCallback, useMemo, useRef, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'

import {Telegram, WhatsApp, Sms, Copy} from 'assets/icons'
import {AnchorTag, Snackbar} from 'components'
import {copyToClipboard} from 'utils'
import {whatsAppShareLink, telegramShareLink, smsShareLink} from 'shared'

import style from './socialReferralCode.module.scss'

function SocialReferralCode({
  referralCode,
  copyMood,
  socialsMood,
  className = '',
}) {
  const initialStates = useMemo(
    () => ({
      referralCopyStatus: false,
    }),
    [],
  )

  const [state, setState] = useState(initialStates)

  const {t} = useTranslation()
  const tRef = useRef(t)

  const {referralCopyStatus} = state

  const socials = useMemo(
    () => [
      {
        id: 'sms',
        value: <Sms />,
        link: `${smsShareLink}`,
        title: tRef.current('socialMedia.sms'),
      },
      {
        id: 'telegram',
        value: <Telegram />,
        link: `${telegramShareLink}`,
        title: tRef.current('socialMedia.telegram'),
      },

      {
        id: 'whatsApp',
        value: <WhatsApp />,
        link: `${whatsAppShareLink}`,
        title: tRef.current('socialMedia.whatsApp'),
      },
    ],
    [],
  )

  const copyJustReferralHandler = useCallback(() => {
    setState(prev => ({
      ...prev,
      referralCopyStatus: true,
    }))
  }, [])

  useEffect(() => {
    let timeout = null

    if (!timeout && referralCopyStatus) {
      copyToClipboard(referralCode)

      timeout = setTimeout(() => {
        setState(prev => ({
          ...prev,
          referralCopyStatus: false,
        }))
      }, 500000)
    }

    return () => clearTimeout(timeout)
  }, [referralCode, referralCopyStatus])

  return (
    <>
      {referralCode && (
        <>
          <ul className={`${style['social-list']} ${className}`}>
            {copyMood && (
              <li
                className={style['social-item']}
                onClick={() => copyJustReferralHandler()}
              >
                <Copy />
              </li>
            )}

            {socialsMood &&
              socials.map(social => (
                <li className={style['social-item']} key={social.id}>
                  <AnchorTag
                    title={social.title}
                    content={social.value}
                    target={'_blank'}
                    href={`${social.link}${window.location.hostname}${
                      window.location.port && window.location.port !== '80'
                        ? `:${window.location.port}`
                        : ''
                    }/?referralCode=${referralCode}`}
                  />
                </li>
              ))}
          </ul>
          {referralCopyStatus && (
            <Snackbar
              setState={setState}
              text={tRef.current('notices.copySuccess')}
              type={'success'}
              stateName="referralCopyStatus"
            />
          )}
        </>
      )}
    </>
  )
}

SocialReferralCode.propTypes = {
  referralCode: PropTypes.string,
  copyMood: PropTypes.bool,
  socialsMood: PropTypes.bool,
}

export default SocialReferralCode

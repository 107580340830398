import {useEffect, useRef} from 'react'
import * as Sentry from '@sentry/browser'

import {
  PRODUCTION,
  googleTagManagerTrackingId,
  nodeENV,
  sentryDSN,
} from 'shared'

function ThirdPartyScripts() {
  const documentRef = useRef(document)

  useEffect(() => {
    if (nodeENV === PRODUCTION) {
      const scriptTag = documentRef.current.createElement('script')
      scriptTag.innerText = (function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'})
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', googleTagManagerTrackingId)

      const noscriptTag = documentRef.current.createElement('noscript')
      const iframeTag = documentRef.current.createElement('iframe')
      iframeTag.src = `https://www.googletagmanager.com/ns.html?id=${googleTagManagerTrackingId}`
      iframeTag.height = 0
      iframeTag.width = 0
      iframeTag.style.display = 'none'
      iframeTag.style.visibility = 'hidden'
      noscriptTag.appendChild(iframeTag)

      documentRef.current.head.appendChild(scriptTag)
      documentRef.current.body.prepend(noscriptTag)
    }
  }, [])

  useEffect(() => {
    Sentry.init({
      dsn: sentryDSN,
    })
  }, [])

  return <></>
}

export default ThirdPartyScripts
